<template>
  <icon-base :width="size + 'px'" :fill="color">
    <path class="st0" d="M162.6,190.6l-40.5-40.5h-16.8v-9.3h5.9c1.5,0,2.7-1.2,2.7-2.7v-3.7h20.5c1.7,0,3.1-1.4,3.1-3.1v-5.6  c0-1.7-1.4-3.1-3.1-3.1h-20.5v-5.5c25.6-6.6,44.5-29.9,44.5-57.6c0-32.8-26.7-59.5-59.5-59.5c-32.8,0-59.5,26.7-59.5,59.5  c0,27.6,18.9,50.9,44.5,57.6v20.9c0,1.5,1.2,2.7,2.7,2.7h5.9v9.3H78.1l-40.7,40.7c-2,2-2,5.4,0,7.4c1,1,2.4,1.5,3.7,1.5  s2.7-0.5,3.7-1.5l37.6-37.6h10.1v26c0,1.8,1.5,3.3,3.3,3.3h6c1.8,0,3.3-1.5,3.3-3.3v-26h12.5l37.4,37.4c2,2,5.4,2,7.4,0  C164.6,196,164.6,192.6,162.6,190.6z M96.2,110.6c0-1.5,1.2-2.8,2.8-2.8c1.5,0,2.8,1.2,2.8,2.8c0,1.5-1.2,2.8-2.8,2.8  C97.4,113.4,96.2,112.2,96.2,110.6z M136.9,97.7c-1.1,1.1-2.8,1.1-3.9,0s-1.1-2.8,0-3.9s2.8-1.1,3.9,0S137.9,96.6,136.9,97.7z   M149.8,57c1.5,0,2.8,1.2,2.8,2.8s-1.2,2.8-2.8,2.8c-1.5,0-2.8-1.2-2.8-2.8S148.3,57,149.8,57z M133,21.8c1.1-1.1,2.8-1.1,3.9,0  s1.1,2.8,0,3.9s-2.8,1.1-3.9,0S131.9,22.9,133,21.8z M98.9,6.1c1.5,0,2.8,1.2,2.8,2.8c0,1.5-1.2,2.8-2.8,2.8c-1.5,0-2.8-1.2-2.8-2.8  C96.2,7.3,97.4,6.1,98.9,6.1z M61,21.8c1.1-1.1,2.8-1.1,3.9,0c1.1,1.1,1.1,2.8,0,3.9c-1.1,1.1-2.8,1.1-3.9,0S59.9,22.9,61,21.8z   M48,62.5c-1.5,0-2.8-1.2-2.8-2.8S46.5,57,48,57c1.5,0,2.8,1.2,2.8,2.8S49.5,62.5,48,62.5z M64.9,97.7c-1.1,1.1-2.8,1.1-3.9,0  s-1.1-2.8,0-3.9s2.8-1.1,3.9,0C65.9,94.9,65.9,96.6,64.9,97.7z M56.6,59.7c0-23.3,19-42.3,42.3-42.3c23.3,0,42.3,19,42.3,42.3  s-19,42.3-42.3,42.3C75.6,102.1,56.6,83.1,56.6,59.7z"/>`
  </icon-base>
</template>

<script>
export default {
  name: 'iconLightRing',
  components: {
    iconBase: () => import('@/common/components/iconBase.vue')
  },
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: ''
    },
    enableBackground: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
.st1 {
  fill: #ffffff;
}
.st2 {
  clip-path: url(#SVGID_2_);
}
.st3 {
  fill: #ea3838;
}
.st4 {
  fill: #72bf58;
}
</style>

